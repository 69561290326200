<template>
  <minePage :info="{}" :cellOptions="cellOptions" @cellChange="cellChange"></minePage>
</template>

<script>
import minePage from '@components/minePage'

export default {
  name: 'mine',
  components: { minePage },
  data() {
    return {
      cellOptions: [
        {
          label: '维修响应记录',
          icon: require('@/icons/imgs/workManager-mine-cell-1.png'),
          page: 'repairResponseRecord',
          path: 'deviceView1/reponseHome'
        },
        {
          label: '催单响应记录',
          icon: require('@/icons/imgs/workManager-mine-cell-2.png'),
          page: 'remindResponseRecord',
          path: 'deviceView1/reponseHome'
        },
        {
          label: '报修记录',
          icon: require('@/icons/imgs/workManager-mine-cell-3.png'),
          page: 'repairRecord',
          path: 'deviceView1/reponseHome'
        },
        {
          label: '维修审核记录',
          icon: require('@/icons/imgs/workManager-mine-cell-4.png'),
          page: 'checkRecord',
          path: 'deviceView1/reponseHome'
        },
        {
          label: '维修处理记录',
          icon: require('@/icons/imgs/workManager-mine-cell-5.png'),
          page: 'dealRecord',
          path: 'deviceView1/reponseHome'
        }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    cellChange(e) {
      this.$push(e.path, { tab: e.page })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
</style>
